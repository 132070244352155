import React, { Component } from 'react';
import {
  Link,
  withRouter
} from 'react-router-dom';
import './AppHeader.css';
import { Layout, Menu, Dropdown, Icon, Space, Modal } from 'antd';
import LanguageSelect from './LanguageSelect';
import logo from '../assets/logo3.png';
import { decodeToken } from '../util/Helpers';
import { ACCESS_TOKEN } from '../constants';
const Header = Layout.Header;
import {
  TeamOutlined,
  QuestionCircleOutlined,
  ReadOutlined
} from '@ant-design/icons';

const appVersion = APP_VERSION;

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false
    }
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

   showModal = () => {
    this.setState({isModalVisible: true});
  };

   handleOk = () => {
    this.setState({isModalVisible: false});
  };

   handleCancel = () => {
    
    this.setState({isModalVisible: false});
  };

  handleMenuClick({ key }) {
    if (key === "logout") {
      this.props.onLogout();
    }
  }

  render() {
    const selectedLanguage = localStorage.getItem("i18nextLng") || "de";
    let menuItems;
    let impersonated = false

    const token = localStorage.getItem(ACCESS_TOKEN);
    const decodedToken = decodeToken(token);
    if (decodedToken && decodedToken.isImpersonation) {
      impersonated = true;
    }
    if (this.props.currentUser) {
      menuItems = [
        <Menu.Item key="forum-12" icon={<ReadOutlined />} onClick={this.showModal}>
        <Link to='/forum'></Link>
     </Menu.Item>,
        <Menu.Item key="12" icon={<QuestionCircleOutlined />}>
                   <Link to={{ pathname: "https://www.kultursaat.org/dateien/PV/pv_manual_2022.pdf" }} target="_blank" />

                </Menu.Item>,
        <Menu.Item key="/profile" className="profile-menu">
          <ProfileDropdownMenu currentUser={this.props.currentUser} impersonated={impersonated}/>
        </Menu.Item>,
        <Menu.Item key="/profile1" className="profile-menu1">
          <LanguageSelect />
        </Menu.Item>
      ];
    } else {
      menuItems = [
        <Menu.Item key="/login">
          <Link to="/login">Login</Link>
        </Menu.Item>,
      ];
    }

    return (
      <Header className="app-header">
        <Modal title="Basic Modal" visible={this.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
          <p>Some contents...</p>
        </Modal>
        <div className="container">
          <Space>
            <div>
              <Link to="/"><img width={150} src={logo} /></Link>
            </div>
            {'v' + appVersion}
          </Space>
          <Menu
            className="app-menu"
            mode="horizontal"
            selectedKeys={[this.props.location.pathname]}
            style={{ lineHeight: '64px' }} >
            {menuItems}
          </Menu>
        </div>
      </Header>
    );
  }
}

function ProfileDropdownMenu(props) {
  const dropdownMenu = (
    <Menu onClick={props.handleMenuClick} className="profile-dropdown-menu">
      <Menu.Item key="user-info" className="dropdown-item" disabled>
        <div className="user-full-name-info">
          {props.currentUser.name}  {props.impersonated ? " (Admin)" : ""}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="profile" className="dropdown-item">
      {/* let linkText = `/breeders/profile/${id}/${profile}` */}
        <Link to={`/breeders/profile/${props.currentUser.breederInfo.id}/1`}>Profil</Link>
      </Menu.Item>
      {/* <Menu.Item key="tasks" className="dropdown-item">
        <Link to={`/users/${props.currentUser.username}`}>Aufgaben</Link>
      </Menu.Item> */}
      {/* <Menu.Item key="logout" className="dropdown-item" >
        <a onClick={props.handleLogout}>Logout</a>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <Dropdown
      overlay={dropdownMenu}
      trigger={['click']}
      getPopupContainer={() => document.getElementsByClassName('profile-menu')[0]}>
      <a className="ant-dropdown-link">
        <TeamOutlined />

        {/* <Icon type="user" className="nav-icon" style={{ marginRight: 0 }} /> <Icon type="down" /> */}
      </a>
    </Dropdown>
  );
}


export default withRouter(AppHeader);
