import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ACCESS_TOKEN } from '../../constants';
import {
    Badge,
    Card,
    Descriptions,
    Divider,
    Table,
    Layout,
    Button,
    Input,
    List,
    Row,
    Col,
    Form,
    Skeleton,
    PageHeader,
    Space,
    message,
    Popconfirm,
    notification
} from 'antd';
import { getFullUserProfile, getRoles, editBreeder, sendInviteEmail, impersonateUser, deleteUser } from '../../util/APIUtils';
import UserRoles from './UserRoles';
import { EmailOutlined } from '@mui/icons-material';
import FinancialInfo from './FinancialInfo';
import UserInfo from './UserInfo';
import { set } from 'lodash';

function UserProfile(props) {

    console.log(props)
    const [form] = Form.useForm();
    let { id } = props.match.params;
    const { t } = useTranslation();
    const [userProfile, setUserProfile] = React.useState(null)
    const [roles, setRoles] = React.useState([]);
    const [loading, setLoading] = React.useState(false)
    const [editing, setEditing] = React.useState(false)
    let currentUser = props.currentUser;


    const loadBreederInfo = React.useCallback(async () => {
        console.log("Loading info")
        let promise;

        promise = getFullUserProfile(id);
        if (!promise) {
            return;
        }

        setLoading(true)

        promise.then(response => {
            console.log(response);
            setUserProfile(response)
            setLoading(false)
        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, []);



    const getAllRoles = React.useCallback(async () => {
        console.log("Loading info")
        let promise;

        promise = getRoles();
        if (!promise) {
            return;
        }

        setLoading(true)

        promise.then(response => {
            console.log(response);
            setRoles(response)
            setLoading(false)


        }).catch(error => {
            console.log(error)
            setLoading(false)
        });
    }, []);


    useEffect(() => {
        loadBreederInfo();
        getAllRoles();
    }, [loadBreederInfo, getAllRoles]);

    const handleBack = (e) => {
        props
            .history
            .goBack();
    };


    const sendEmail = () => {
        let promise;
        promise = sendInviteEmail(userProfile.id);

        if (!promise) {
            return;
        }

        setLoading(true)

        promise.then(response => {
            setLoading(false);
            message.success("E-Mail gesendet!")

        }).catch(error => {
            console.log(error)
            setLoading(false);
            message.error("Das Senden der E-Mail ist fehlgeschlagen")
        });

    }

    const impersonate = () => {
        let promise;

        let impersonationRequest = {
            "usernameToImpersonate": userProfile.email || ""
        }

        promise = impersonateUser(impersonationRequest);
        if (!promise) {
            return;
        }
        setLoading(true)
        promise.then(response => {

            props.resetLogin();
            localStorage.setItem(ACCESS_TOKEN, response.accessToken);
            props.handleLogin();
            setLoading(false);
            props.history.push("/")
        }).catch(error => {
            console.log(error)
            setLoading(false);
            message.error("Das Einloggen ist fehlgeschlagen")
        });
    }

    const deleteUserProfile = () => {
        let promise;
        promise = deleteUser(userProfile.id);

        if (!promise) {
            return;
        }

        setLoading(true)

        promise.then(response => {
            console.log(response)
            if (response) {
                setLoading(false);
                notification.success({ message: "Benutzer gelöscht", description: "Der Benutzer wurde erfolgreich gelöscht.", placement: 'topLeft' });
                props.history.push('/user-management'); // Navigate to the user listing page after deletion
            } else {
                setLoading(false);
                notification.error({ message: "Das Löschen ist fehlgeschlagen", description: "Benutzer konnte nicht gelöscht werden. Es bestehen Abhängigkeiten!.", placement: 'topLeft' });
            }
        }).catch(error => {
            console.log(error);
            setLoading(false);
            notification.error({ message: "Fehler", description: "Das Löschen des Benutzers ist fehlgeschlagen.", placement: 'topLeft' });
        });
    }

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => handleBack()}
                extra={<Space>
                    {/* <Button
                        type="primary"
                        icon={<EmailOutlined />}
                        loading={loading}
                        onClick={() => sendEmail()}
                    >
                    </Button> */}
                    <Popconfirm title="Sind Sie sicher, dass Sie sich als dieser Benutzer anmelden möchten?" onConfirm={() => impersonate()}>
                        <Button>
                            {"Impersonate"}
                        </Button>
                    </Popconfirm>
                    {userProfile && !userProfile.status && (
                        <Popconfirm title="Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?" onConfirm={() => deleteUserProfile()}>
                            <Button type="danger">
                                {"Benutzer löschen"}
                            </Button>
                        </Popconfirm>
                    )}
                </Space>
                }
                title={userProfile && userProfile.breederInfo?.shortName ? userProfile.breederInfo?.shortName.toUpperCase() : ""}
                subTitle={userProfile && userProfile.breederInfo?.fullName} />
            <Card bordered={true}
                loading={loading}
            >
                {userProfile &&
                    <div>
                        <UserInfo userInfo={userProfile} reload={loadBreederInfo} id={userProfile.id} breederId={userProfile.breederInfo?.id} loadBreederInfo={loadBreederInfo} />
                        <UserRoles roles={userProfile.roles} allRoles={roles} reload={loadBreederInfo} id={userProfile.id} />
                        <FinancialInfo breederId={userProfile.breederInfo?.id} financialInfo={userProfile.breederInfo?.financialInfo} loadBreederInfo={loadBreederInfo} />
                    </div>}
            </Card>
        </div>
    )
}

export default UserProfile;
