import React, { Component } from 'react'
import { Card, PageHeader, Select, Table, Typography, Divider, Checkbox, Space, Tag, Tooltip } from 'antd'
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { getBudgetOverViews, getBreederCropSummary, getYears, getBreederCropSummaryReportXls, getBreederCropSummaryReportPdf } from '../util/APIUtils';
import { formatCurrency } from '../util/Helpers';
import TableSummaryCell from './TableSummaryCell';
import YearSelect from '../common/YearSelect';
import './Finance.css'

import {

    FileExcelOutlined,
    FilePdfOutlined
} from '@ant-design/icons';

const { Text, Title } = Typography;

const fg_style = "ccd5ae"
const prio_style = "e9edc9"

class BreederCropSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: null,
            projectYears: [],
            isLoading: false,
            visible: false,
            priorityChecked: true,
            fgChecked: true,
            payoutChecked: false,
            breederCropSummary: [],
            fullList: []
        };
    }

    fetchYears() {
        this.setState({ isLoading: true });
        let promise;
        promise = getYears();
        if (!promise) {
            return;
        }

        promise.then(response => {
            this.setState({ projectYears: response.reverse() })
            this.setState({ isLoading: false });

        }).catch(error => {
            this.setState({ isLoading: false });
        });
    }

    getReportPdf = () => {
        const win = window.open(getBreederCropSummaryReportPdf(this.state.year), '_blank');
        if (win != null) {
            win.focus();
        }
    }

    getReport = () => {
        const win = window.open(getBreederCropSummaryReportXls(this.state.year), '_blank');
        if (win != null) {
            win.focus();
        }

    }

    loadBudgetOverviews(val) {
        let promise;
        // if (!this.state.year) {
        //     return;
        // }

        console.log("Loading")
        promise = getBreederCropSummary(val);

        if (!promise) {
            return;
        }

        this.setState({ isLoading: true });
        promise.then(response => {
            // console.log(response);
            this.setState({
                breederCropSummary: response,
                isLoading: false
            })
        }).catch(error => {
            this.setState({ isLoading: false })
        });
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            this
                .props
                .history
                .push("/login");
        }
        this.fetchYears();

    }

    handleYearInput = (val) => {
        console.log(val);
        this.setState({
            year: val
        })

        console.log(this.state.year);
        this.loadBudgetOverviews(val);
    }

    onFgChange = (val) => {
        console.log(val)
        this.setState({ fgChecked: !this.state.fgChecked });
        console.log(this.state.fgChecked)
    }


    onPayoutChange = (val) => {
        console.log(val)
        this.setState({ payoutChecked: !this.state.payoutChecked });
        console.log(this.state.payoutChecked)
    }

    onPrioChange = (val) => {
        console.log(val)
        this.setState({ priorityChecked: !this.state.priorityChecked });
        console.log(this.state.priorityChecked)
    }


    render() {
        const { t } = this.props;

        let columns = [
            {
                title: "Züchter",
                dataIndex: 'breederShortName',
                key: 'breederShortName',
                width: 20,
                sortDirections: ['descend', 'ascend'],
                ellipsis: {
                    showTitle: false,
                  },
                  render: text => (
                    <Tooltip placement="topLeft" title={text}>
                      {text}
                    </Tooltip>
                  )
            },
            {
                title: "Typ",
                dataIndex: 'projectType',
                key: 'projectType',
                align: "left",
                width: 20,
                ellipsis: {
                    showTitle: false,
                  },
                // width: 300,
            },
            {
                title: "Kultur",
                dataIndex: 'cropName',
                key: 'cropName',
                align: "left",
                minWidth: 50,
                ellipsis: {
                    showTitle: false,
                  },
            },

            // ####### PLAN
            {
                title: "Gesamt Kalkulation",
                type: "prio",
                dataIndex: 'totalItemBudget',
                key: 'totalItemBudget',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#bfd8bd" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                }
                // width: 300,
            },
            {
                title: "#",
                type: "prio",
                dataIndex: 'totalItemCount',
                key: 'totalItemCount',
                align: "left",
                width: 20,
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#bfd8bd" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                }
            },
            {
                title: "Tätigkeiten",
                type: "prio",
                dataIndex: 'totalAccountingBudget',
                key: 'totalAccountingBudget',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#dde7c7" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                }
                // width: 300,
            },

            {
                title: "Analysen",
                type: "prio",
                dataIndex: 'totalExamBudget',
                key: 'totalExamBudget',
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#edeec9" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
                align: "right",
                // width: 300,
            },


            // #### PLAN END

            // #########  Betrag FG 
            {
                title: "FG OK",
                dataIndex: 'totalFgOkAmount',
                type: "betrag_fg",
                key: 'totalFgOkAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#d5cdbb" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
                align: "right",
            },
            {
                title: "#",
                type: "betrag_fg",
                dataIndex: 'totalFgOkCount',
                key: 'totalFgOkCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#d5cdbb" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
            },
            {
                title: "FG Zurückgestellt",
                type: "betrag_fg",
                dataIndex: 'totalFgOnHoldAmount',
                key: 'totalFgOnHoldAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#e8d1be" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "betrag_fg",
                dataIndex: 'totalFgOnHoldCount',
                key: 'totalFgOnHoldCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#e8d1be" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
                // width: 260
            },
            {
                title: "FG Abgelehnt",
                type: "betrag_fg",
                dataIndex: 'totalFgRejectAmount',
                key: 'totalFgRejectAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#f1d3bf" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "betrag_fg",
                dataIndex: 'totalFgRejectCount',
                key: 'totalFgRejectCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#f1d3bf" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
                // width: 260
            },

            // #########  Betrag FG END
            // #########  Payout
            {
                title: "Auszahl OK",
                type: "payout",
                dataIndex: 'totalPayoutOkAmount',
                key: 'totalPayoutOkAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fcde73" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "payout",
                dataIndex: 'totalPayoutOkCount',
                key: 'totalPayoutOkCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fcde73" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
                // width: 260
            },
            {
                title: "Auszahl Zurückgestellt",
                type: "payout",
                dataIndex: 'totalPayoutOnHoldAmount',
                key: 'totalPayoutOnHoldAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fde89b" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "payout",
                dataIndex: 'totalPayoutOnHoldCount',
                key: 'totalPayoutOnHoldCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fde89b" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
                // width: 260
            },
            {
                title: "Auszahl Abgelehnt",
                type: "payout",
                dataIndex: 'totalPayoutRejectAmount',
                key: 'totalPayoutRejectAmount',
                align: "right",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fdecaf" }
                        },
                        children: <span><b>
                            <Text>  {text != null || text == 0 ? formatCurrency(text.toFixed(2)) : "0.00"}</Text>
                        </b>
                        </span>
                    };
                },
            },
            {
                title: "#",
                type: "payout",
                dataIndex: 'totalPayoutRejectCount',
                key: 'totalPayoutRejectCount',
                align: "left",
                render(text, record) {
                    return {
                        props: {
                            style: { background: "#fdecaf" }
                        },
                        children: <span><Tag color="gold">{text}</Tag>
                        </span>
                    };
                },
                width: 40,
                // width: 260
            },
            // ########### FG FIXED



        ];

        if (!this.state.fgChecked) {
            columns = columns.filter(c => c.type != "betrag_fg")
        }
        if (!this.state.payoutChecked) {
            columns = columns.filter(c => c.type != "payout")
        }

        if (!this.state.priorityChecked) {
            columns = columns.filter(c => c.type != "prio")
        }


        // const fullList = this.state.cropSummaryList.push(...this.state.projectTypeSummaryList)
        return (
            <div>
                <PageHeader
                    className="site-page-header"
                    title="Budgetübersicht nach Zuchtbetrieb"
                    subTitle=""
                />

                <Card bordered={true} loading={this.state.isLoading} extra="">
                    <Space>
                        <YearSelect
                            setItem={this.handleYearInput}
                            selectedItem={this.state.year}
                            defaultValue={"2023"}
                        />
                        
                        <Checkbox
                            checked={this.state.priorityChecked}
                            onChange={this.onPrioChange}
                        >
                            Plan
                        </Checkbox>
                        <Checkbox
                            checked={this.state.fgChecked}
                            onChange={this.onFgChange}
                        >
                            Betrag FG
                        </Checkbox>
                        <Checkbox
                            checked={this.state.payoutChecked}
                            onChange={this.onPayoutChange}
                        >
                            Auszahlbetrag
                        </Checkbox>

                        {this.state.year ? <Space> <FileExcelOutlined style={{ fontSize: '28px', color: '#08c' }} onClick={this.getReport} /></Space> : ""}
                    </Space>
                    <Divider dashed />
                    {this.state.breederCropSummary.length > 0 &&
                        <Table
                        className="finance-table"
                        size="small"
                        columns={columns}
                        style={{ fontSize: '10px' }}
                        dataSource={this.state.breederCropSummary}
                        scroll={{ x: 'max-content' }}
                        loading={this.state.isLoading}
                        pagination={false}
                        rowClassName={(record, index) => {
                            if (record.type === 'subtotal_ptype') {
                                return 'table-row-pt';
                            } else if (record.type === 'subtotal_breeder') {
                                return 'table-row-dark';
                            }
                        }}
                        summary={(pageData) => {
                            let grandTotal = {
                                totalItemBudget: 0,
                                totalItemCount: 0,
                                totalAccountingBudget: 0,
                                totalExamBudget: 0,
                                totalFgOkAmount: 0,
                                totalFgOkCount: 0,
                                totalFgOnHoldAmount: 0,
                                totalFgOnHoldCount: 0,
                                totalFgRejectAmount: 0,
                                totalFgRejectCount: 0,
                                totalPayoutOkAmount: 0,
                                totalPayoutOkCount: 0,
                                totalPayoutOnHoldAmount: 0,
                                totalPayoutOnHoldCount: 0,
                                totalPayoutRejectAmount: 0,
                                totalPayoutRejectCount: 0,
                            };
                    
                            // Compute totals for each column
                            this.state.breederCropSummary.forEach((item) => {
                                grandTotal.totalItemBudget += item.totalItemBudget || 0;
                                grandTotal.totalItemCount += item.totalItemCount || 0;
                                grandTotal.totalAccountingBudget += item.totalAccountingBudget || 0;
                                grandTotal.totalExamBudget += item.totalExamBudget || 0;
                                grandTotal.totalFgOkAmount += item.totalFgOkAmount || 0;
                                grandTotal.totalFgOkCount += item.totalFgOkCount || 0;
                                grandTotal.totalFgOnHoldAmount += item.totalFgOnHoldAmount || 0;
                                grandTotal.totalFgOnHoldCount += item.totalFgOnHoldCount || 0;
                                grandTotal.totalFgRejectAmount += item.totalFgRejectAmount || 0;
                                grandTotal.totalFgRejectCount += item.totalFgRejectCount || 0;
                                grandTotal.totalPayoutOkAmount += item.totalPayoutOkAmount || 0;
                                grandTotal.totalPayoutOkCount += item.totalPayoutOkCount || 0;
                                grandTotal.totalPayoutOnHoldAmount += item.totalPayoutOnHoldAmount || 0;
                                grandTotal.totalPayoutOnHoldCount += item.totalPayoutOnHoldCount || 0;
                                grandTotal.totalPayoutRejectAmount += item.totalPayoutRejectAmount || 0;
                                grandTotal.totalPayoutRejectCount += item.totalPayoutRejectCount || 0;
                            });
                    
                            // Render the summary row, conditionally including totals based on state
                            return (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0} colSpan={3}>
                                        <Text strong>Gesamt</Text>
                                    </Table.Summary.Cell>
                    
                                    {/* Plan Columns (priorityChecked) */}
                                    {this.state.priorityChecked && (
                                        <>
                                            <Table.Summary.Cell index={3} align="right">
                                                <Text strong>{formatCurrency(grandTotal.totalItemBudget.toFixed(2))}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={4} align="left">
                                                <Tag color="gold">{grandTotal.totalItemCount}</Tag>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={5} align="right">
                                                <Text strong>{formatCurrency(grandTotal.totalAccountingBudget.toFixed(2))}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={6} align="right">
                                                <Text strong>{formatCurrency(grandTotal.totalExamBudget.toFixed(2))}</Text>
                                            </Table.Summary.Cell>
                                        </>
                                    )}
                    
                                    {/* FG Columns (fgChecked) */}
                                    {this.state.fgChecked && (
                                        <>
                                            <Table.Summary.Cell index={7} align="right">
                                                <Text strong>{formatCurrency(grandTotal.totalFgOkAmount.toFixed(2))}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={8} align="left">
                                                <Tag color="gold">{grandTotal.totalFgOkCount}</Tag>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={9} align="right">
                                                <Text strong>{formatCurrency(grandTotal.totalFgOnHoldAmount.toFixed(2))}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={10} align="left">
                                                <Tag color="gold">{grandTotal.totalFgOnHoldCount}</Tag>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={11} align="right">
                                                <Text strong>{formatCurrency(grandTotal.totalFgRejectAmount.toFixed(2))}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={12} align="left">
                                                <Tag color="gold">{grandTotal.totalFgRejectCount}</Tag>
                                            </Table.Summary.Cell>
                                        </>
                                    )}
                    
                                    {/* Payout Columns (payoutChecked) */}
                                    {this.state.payoutChecked && (
                                        <>
                                            <Table.Summary.Cell index={13} align="right">
                                                <Text strong>{formatCurrency(grandTotal.totalPayoutOkAmount.toFixed(2))}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={14} align="left">
                                                <Tag color="gold">{grandTotal.totalPayoutOkCount}</Tag>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={15} align="right">
                                                <Text strong>{formatCurrency(grandTotal.totalPayoutOnHoldAmount.toFixed(2))}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={16} align="left">
                                                <Tag color="gold">{grandTotal.totalPayoutOnHoldCount}</Tag>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={17} align="right">
                                                <Text strong>{formatCurrency(grandTotal.totalPayoutRejectAmount.toFixed(2))}</Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={18} align="left">
                                                <Tag color="gold">{grandTotal.totalPayoutRejectCount}</Tag>
                                            </Table.Summary.Cell>
                                        </>
                                    )}
                                </Table.Summary.Row>
                            );
                        }}
                    />
                    }



                </Card>

            </div>
        )
    }
}

export default withTranslation()(withRouter(BreederCropSummary));
