import React, { useState, useEffect, useRef } from 'react';
import { getSortens } from '../util/APIUtils';
import {
    Select,
} from 'antd';

function SortenSelect({setItem,  selectedItem, cropId }) {

    let useMyData = getSortens();

    const { isLoading, error, data, refetch } = useMyData(
        (jsonData) => jsonData,
    );

    const handleChange = selectedItem => {
        setItem(selectedItem);
    };


    const filteredSortens = cropId ? data.filter(sorten => sorten.cropId == cropId) : data;

    return (
        <>

            <Select
                showSearch
                mode="single"
                loading={isLoading}
                onChange={handleChange}
                optionFilterProp="children"
                placeholder={"Sorte auswählen"}
                defaultValue={selectedItem}
                // onChange={handleChange}

                style={{ width: '100%' }}
            >
                 {!isLoading &&
                filteredSortens.map(item => (
                    <Select.Option key={item.id} value={item.designation}>
                        {item.designation} - {item.cropName}
                    </Select.Option>
                ))
}
            </Select> 

        </>
    )
}

export default SortenSelect